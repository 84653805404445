import { UPDATE_LINKS } from '../actions/linksActions';
import { UPDATE_FILTERS, CLEAR_FILTERS } from '../actions/linksActions';
import { EVERYONE_OPTION } from '../sheets';
import moment from 'moment';

export function linksReducer(state = '', action){
	switch(action.type){
		case UPDATE_LINKS:
			return action.payload.links;
		default:
			return state;
	}
}
export function keyedLinksReducer(state = '', action){
	switch(action.type){
		case UPDATE_LINKS:
			//a trick to performantly key by an object https://stackoverflow.com/a/44325124
			//eslint-disable-next-line
			return action.payload.links.reduce((keyed, link)=>(keyed[link['Title']] = link, keyed), {});
		default:
			return state;
	}
}
export function categoriesReducer(state = '', action){
	switch(action.type){
		case UPDATE_LINKS:
			return action.payload.categories;
		default:
			return state;
	}
}
export function skillLevelsReducer(state = '', action){
	switch(action.type){
		case UPDATE_LINKS:
			return action.payload.skillLevels;
		default:
			return state;
	}
}

export const initialFiltersState = {
	search:'',
	category:'',
	skillLevel:'',
	showAll:false,
}

export function filtersReducer(state = '', action){
	switch(action.type){
		case UPDATE_FILTERS:
			if( !action.payload.type ){
				return state;
			}
			return {
				...initialFiltersState,
				showAll:state.showAll,
				[action.payload.type]:action.payload.filter
			};
		case CLEAR_FILTERS:
			return {
				...initialFiltersState,
				showAll:state.showAll,
			};
		default:
			return state;
	}
}

//TODO refactor this into a "selector" https://redux.js.org/recipes/computing-derived-data
export function filteredLinks(links, keyedLinks, recommendations, filters, user){
	let recommendationsForFiltering;
	if( filters.showAll ){
		recommendationsForFiltering = links.map(link=>{
			let existingRecommendation = recommendations.find(recommendation=>(recommendation['Person'] === user && recommendation['Link'] === link['Title']));
			if( existingRecommendation ){
				return existingRecommendation;
			} else {
				return createRecommendationPlaceholder(link, user);
			}
		});
	} else {
		recommendationsForFiltering = [...recommendations];

		//generate recommendations for the user for recommendations marked "everyone"
		recommendations.reduce((recommendationsWithEveryoneLinks, recommendation)=>{
			if( recommendation['Person'] === EVERYONE_OPTION ){
				if( recommendationsForFiltering.some(existingRecommendation=>(existingRecommendation['Link'] === recommendation['Link'] && existingRecommendation['Person'] === user)) ){
					return recommendationsWithEveryoneLinks;
				}
				recommendationsWithEveryoneLinks.push(createRecommendationPlaceholder(keyedLinks[recommendation['Link']], user, recommendation));
			}
			return recommendationsWithEveryoneLinks;
		}, recommendationsForFiltering);
	}

	return recommendationsForFiltering.filter(recommendation=>{
		let link = keyedLinks[recommendation['Link']];
		if( filters.search ){
			let query = filters.search.toLowerCase();
			if( !link['Title'].toLowerCase().includes(query) && !link['URL'].toLowerCase().includes(query) && !link['Notes'].toLowerCase().includes(query) ){
				return false;
			}
		}
		if( filters.category && filters.category !== link['Category'] ){
			return false;
		}
		if( filters.skillLevel && filters.skillLevel !== link['Skill Level'] ){
			return false;
		}

		return (filters.showAll || recommendation['Person'] === user);
	}).sort((a,b)=>{
		return a['Date Added'].valueOf() - b['Date Added'].valueOf();
	}).reverse();
}

function createRecommendationPlaceholder(link, user, recommendation = null){
	return {
		'Date Added': recommendation ? moment(recommendation['Date Added']) : moment(),
		'Link':link['Title'],
		'Person':user,
		'Date Viewed':'',
		'Favorited':'',
		'Hidden':'',
	}
}