import React, { Component } from 'react';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import {updateFilters, clearFilters} from '../../actions/linksActions';
import {filteredLinks} from '../../reducers/linksReducer';
import { URL_EDIT_PREFIX, SPREADSHEET_ID } from '../../sheets';
import {getToken, refreshToken} from '../../index';

import './NewLinks.scss';
import close from '../../images/close.svg';
import closeWhite from '../../images/close-white.svg';
import { visitRecommendation } from '../../actions/recommendationsActions';

class NewLinks extends Component {
	constructor(props){
		super(props);
		this.state = {
			selectedRecommendation:null,
			filters:props.filters,
			detailsTopOffset:undefined,
			recommendationIndicesForMarginTop:[]
		};
		this.selectRecommendation = this.selectRecommendation.bind(this);
		this.stopPropagationOnLinkClick = this.stopPropagationOnLinkClick.bind(this);
		this.closeRecommendation = this.closeRecommendation.bind(this);
	}

	componentDidUpdate(prevProps){
		if(this.state.filters !== this.props.filters){
			this.setState({
				selectedRecommendation:null,
				filters:this.props.filters,
				detailsTopOffset:undefined,
				recommendationIndicesForMarginTop:[]
			});
		}
	}

	selectRecommendation(recommendation, listElement, index){
		this.setState({
			selectedRecommendation:recommendation,
			recommendationIndicesForMarginTop:this.determineIndicesForNextRowElements(listElement, index)
		}, ()=>{
			//putting this in the setState callback allows the margins to re-render before determining the top position
			//which prevents jumping errors if the margins hadn't changed before adjusting the details top position
			let top = listElement.getBoundingClientRect().top;
			top += (window.innerWidth > 800) ? this.sectionScroller.scrollTop : document.documentElement.scrollTop;
			top += listElement.offsetHeight;
			top += 20; //20 for triangle
			top -= (window.innerWidth > 800) ? 0 : document.querySelector('.menu').clientHeight; //in vertical mode, menu is fixed
			this.setState({
				detailsTopOffset:top
			})
		});
	}

	//semi-recursively determines the indices of the items on the next row
	determineIndicesForNextRowElements(element, startingIndex){
		const indices = [];
		let currentIndex = startingIndex;
		const initialOffset = element.offsetTop;
		let nextElement = element.nextSibling;
		currentIndex++;
		let nextRowOffset;
		while( nextElement && initialOffset === nextElement.offsetTop ){
			nextElement = nextElement.nextSibling;
			currentIndex++;
		}
		if( nextElement ){
			indices.push(currentIndex)
			nextRowOffset = nextElement.offsetTop;
			nextElement = nextElement.nextSibling;
			currentIndex++;
		}
		while( nextElement && nextRowOffset === nextElement.offsetTop ){
			indices.push(currentIndex)
			nextElement = nextElement.nextSibling;
			currentIndex++;
		}

		return indices;
	}

	closeRecommendation(){
		this.setState({
			selectedRecommendation:null,
			detailsTopOffset:undefined,
			recommendationIndicesForMarginTop:[]
		});
	}

	stopPropagationOnLinkClick(e){
		e.stopPropagation();
	}

	getFilterHeading(){
		const closeButton = <button onClick={this.props.clearFilters}><img src={closeWhite} alt="Close" /></button>
		if( this.props.filters.search ){
			return <h2>Search results for “{this.props.filters.search}” {closeButton}</h2>
		}
		if( this.props.filters.category ){
			return <h2>Links categorized “{this.props.filters.category}” {closeButton}</h2>
		}
		if( this.props.filters.skillLevel ){
			return <h2>Links marked “{this.props.filters.skillLevel}” {closeButton}</h2>
		}
		return;
	}

	updateFilters( type, filter ){
		this.props.updateFilters(type, filter);
		this.setState({
			selectedRecommendation:null,
			detailsTopOffset:undefined,
			recommendationIndicesForMarginTop:[]
		});
	}

	attemptedTokenRetry = false;

	async visitRecommendation(recommendation){
		const token = await getToken();
		const today = Moment.globalMoment().format('l');
		const isNew = !(recommendation.row);

		if( recommendation.row ){
			const url = URL_EDIT_PREFIX + SPREADSHEET_ID + '/values/' + encodeURIComponent('Recommendations!D'+recommendation.row) + '?valueInputOption=USER_ENTERED&alt=json';

			const request = await fetch(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+token
				},
				 body: JSON.stringify({
					'range': 'Recommendations!D'+recommendation.row,
					'values': [
						[
							today
						]
					]
				})
			});

			const response = await request;
			if( response.status === 200 ){
				this.attemptedTokenRetry = false;
			} else if( response.status === 401 ){
				if( process.env.NODE_ENV !== 'development' ){
					if( this.attemptedTokenRetry ){
						return;
					}
					this.attemptedTokenRetry = true;
					await refreshToken();
					this.visitRecommendation(recommendation);
				}
				return;
			} else {
				alert('There was an error marking the link visited. Please refresh and try again.');
				return;
			}
		} else {
			const url = URL_EDIT_PREFIX + SPREADSHEET_ID + '/values/' + encodeURIComponent('Recommendations!A:F') + ':append?valueInputOption=USER_ENTERED&alt=json&insertDataOption=INSERT_ROWS';

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+token
				},
				body: JSON.stringify({
					"range": "Recommendations!A:F",
					"values": [
						[
							today,
							recommendation['Link'],
							this.props.user,
							today,
							'',
							1
						]
					]
				})
			});

			const data = await response.json();
			if( response.status === 200 ){
				const rowRegex = new RegExp('Recommendations!A(\\d*):');
				const rowMatch = rowRegex.exec(data.updates.updatedRange);
				recommendation.row = ( rowMatch ) ? parseInt(rowMatch[1], 10) : undefined;
				this.attemptedTokenRetry = false;
			} else if( response.status === 401 ){
				if( process.env.NODE_ENV !== 'development' ){
					if( this.attemptedTokenRetry ){
						return;
					}
					this.attemptedTokenRetry = true;
					await refreshToken();
					this.visitRecommendation(recommendation);
				}
				return;
			} else {
				alert('There was an error marking the link visited. Please refresh and try again.');
				return;
			}
		}

		recommendation['Date Viewed'] = today;

		this.props.visitRecommendation(recommendation, isNew);
		this.closeRecommendation();
	}

	render() {
		const detailsSectionHeight = (this.state.selectedRecommendation && this.details) ? this.details.offsetHeight : 0;
		const selectedLink = (this.state.selectedRecommendation) ? this.props.keyedLinks[this.state.selectedRecommendation['Link']] : null;
		const detailsStyling = (this.state.detailsTopOffset) ? {top: this.state.detailsTopOffset + 'px', opacity: 1} : undefined;

		let emptyText;
		//an empty concatenated string represents no active filters
		if( this.props.filters.search + this.props.filters.category + this.props.filters.skillLevel === '' ){
			if(this.props.filters.showAll){
				emptyText = 'You’ve read absolutely everything! Whoa.';
			} else {
				emptyText = 'You’re all caught up!';
			}
		} else {
			if(this.props.filters.showAll){
				emptyText = 'You’ve read all the links like this already! Good work.';
			} else {
				emptyText = <React.Fragment>There aren’t any links like this for you yet. <button onClick={()=>this.updateFilters('showAll', true)}>Try viewing all the links?</button></React.Fragment>;
			}
		}

		// if(this.props.recommendations.length === 0){
			// emptyText = 'Loading…';
		// }

		return (
			<section className="new" ref={ (sectionScroller) => this.sectionScroller = sectionScroller}>
				<header>
					<h1>Concepts Awaiting Mastery</h1>
					{this.getFilterHeading()}
				</header>
				<ul>
					{this.props.keyedLinks && this.props.recommendations.map((recommendation,index)=>{
						let link = this.props.keyedLinks[recommendation['Link']];
						const url = link['URL'];
						const domain = link['domain'];

						const marginOffsetForDetailsPanel = (this.state.selectedRecommendation && this.details && this.state.recommendationIndicesForMarginTop.includes(index)) ? {marginTop:detailsSectionHeight+'px'} : undefined;
						const selectedRecommendationClass = (this.state.selectedRecommendation && this.details && recommendation === this.state.selectedRecommendation) ? 'open' : undefined;

						const classes = [];
						if( selectedRecommendationClass ){
							classes.push(selectedRecommendationClass);
						}
						if( link['Riley Favorite'] ){
							classes.push('riley-favorite');
						}

						return <li tabIndex="0" key={recommendation['Link']} onFocus={(e)=>this.selectRecommendation(recommendation, e.currentTarget, index)} className={classes.join(' ')} style={marginOffsetForDetailsPanel}>
							<h3>{recommendation['Link']}</h3>
							<label className="source">
								Available at <a href={url} onClick={e=>{this.stopPropagationOnLinkClick(e); this.visitRecommendation(recommendation)}} target="_blank" rel="noopener noreferrer">{domain}</a>
							</label>
							{!this.props.filters.showAll && <label className="added">
								<time dateTime={recommendation['Date Added']}>Added <Moment interval={0} fromNow date={recommendation['Date Added']}/></time>
							</label>}
						</li>
					})}
					{this.props.recommendations.length === 0 && <li className="empty">{emptyText}</li>}
				</ul>
				<div className="details-container" ref={ (details) => this.details = details} style={detailsStyling}>
					{this.state.selectedRecommendation && <div className="details">
						<div className="content">
							<section className="note">
								<h4>A Note from Riley</h4>
								<p>{this.props.keyedLinks[this.state.selectedRecommendation['Link']]['Notes']}</p>
							</section>
							<section className="actions">
								<a href={this.props.keyedLinks[this.state.selectedRecommendation['Link']]['URL']} onClick={e=>{this.stopPropagationOnLinkClick(e); this.visitRecommendation(this.state.selectedRecommendation)}} target="_blank" rel="noopener noreferrer">Open</a>
								<label>Categorized in <button onClick={()=>this.updateFilters('category', selectedLink['Category'])}>{selectedLink['Category']}</button></label>
								<label>Marked as <button onClick={()=>this.updateFilters('skillLevel', selectedLink['Skill Level'])}>{selectedLink['Skill Level']}</button></label>
								{/* TODO add the hide button? */}
								{/* <button className="hide">Hide this Link</button> */}
							</section>
						</div>
						<button className="close" onClick={this.closeRecommendation}><img src={close} alt="close"/></button>
					</div>}
				</div>
			</section>
		);
	}
}

const mapStateToProps = (state)=>({
    links:state.links,
    keyedLinks:state.keyedLinks,
    recommendations:filteredLinks(state.links, state.keyedLinks, state.recommendations, state.filters, state.user).filter(recommendation=>!recommendation['Date Viewed']),
    filters:state.filters,
    user:state.user
});

//TODO mark links as visited
const mapActionsToProps = {
  updateFilters:updateFilters,
  clearFilters:clearFilters,
  visitRecommendation:visitRecommendation,
};

export default connect(mapStateToProps, mapActionsToProps)(NewLinks);