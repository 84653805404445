import { UPDATE_RECOMMENDATIONS, FAVORITE_RECOMMENDATION, VISIT_RECOMMENDATION } from '../actions/recommendationsActions';
import moment from 'moment';

export default function recommendationsReducer(state = '', action){
	let recommendations;
	switch(action.type){
		case UPDATE_RECOMMENDATIONS:
			recommendations = action.payload.recommendations.map(recommendation=>{
				recommendation['Date Added'] = moment(recommendation['Date Added']);
				recommendation['Date Viewed'] = ( recommendation['Date Viewed'] ) ? moment(recommendation['Date Viewed']) : recommendation['Date Viewed'];
				return recommendation;
			});
			return recommendations;
		case FAVORITE_RECOMMENDATION:
			recommendations = state.map(recommendation=>{
				if( recommendation.row === action.payload.recommendation.row ){
					recommendation.Favorited = action.payload.recommendation.Favorited;
				}
				return recommendation;
			});
			return recommendations;
		case VISIT_RECOMMENDATION:
			if( action.payload.isNew ){
				recommendations = state.concat([action.payload.recommendation]);
			} else {
				recommendations = state.map(recommendation=>{
					if( recommendation.row === action.payload.recommendation.row ){
						return action.payload.recommendation;
					}
					return recommendation;
				});
			}
			return recommendations;
		default:
			return state;
	}
}
