import React, { Component } from 'react';
import {connect} from 'react-redux';
import {updateUser} from '../../actions/userActions';
import icon from './../../images/yours.svg';

import './Identify.scss';

class Identify extends Component {
	constructor(){
		super();
		this.updateUser = this.updateUser.bind(this);
	}
	updateUser(name){
		this.props.updateUser(name);
	}
	render() {
		return (
			<section className="identify">
				<form>
					<label htmlFor="user" tabIndex={1}><img src={icon} alt=""/> And you are?</label>
					<ul>
						{this.props.people.map(person=><li key={person['Name']} onClick={()=>this.updateUser(person['Name'])} onKeyDown={(e)=>{if(e.key==='Enter')this.updateUser(person['Name'])}} tabIndex={0}><span><img src={person['Photo']} alt={person['Name']}/></span>{person['Name']}</li>)}
					</ul>
					{/* <select name="user" id="user">
						<option value="">– Don’t impersonate anyone, please. –</option>
						{this.props.people.map(person=><option key={person}>{person}</option>)}
					</select> */}
				</form>
			</section>
		);
	}
}

const mapStateToProps = (state)=>({
    people:state.people
});

const mapActionsToProps = {
  updateUser:updateUser
};

export default connect(mapStateToProps, mapActionsToProps)(Identify);