import React, { Component } from 'react';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import {filteredLinks} from '../../reducers/linksReducer';
import { favoriteRecommendation } from '../../actions/recommendationsActions';

import './ViewedLinks.scss';
import {ReactComponent as Heart} from '../../images/heart.svg';
import {getToken, refreshToken} from '../../index';
import { URL_EDIT_PREFIX, SPREADSHEET_ID } from '../../sheets';
import { updateFilters } from '../../actions/linksActions';

class ViewedLinks extends Component {
	constructor(){
		super();
		this.markAsFavorite = this.markAsFavorite.bind(this);
		this.filterByCategory = this.filterByCategory.bind(this);
	}
	filterByCategory(e){
		e.target.blur();
		this.props.updateFilters('category', e.target.value);
	}

	attemptedTokenRetry = false;

	async markAsFavorite(recommendation){
		const token = await getToken();
		const url = URL_EDIT_PREFIX + SPREADSHEET_ID + '/values/' + encodeURIComponent('Recommendations!E'+recommendation.row) + '?valueInputOption=USER_ENTERED&alt=json';

		const newFavoritedStatus = ( recommendation.Favorited === '1' ) ? '' : '1';

		let request = await fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+token
			},
 			body: JSON.stringify({
				'range': 'Recommendations!E'+recommendation.row,
				'values': [
					[
						newFavoritedStatus
					]
				]
			})
		});

		recommendation.Favorited = newFavoritedStatus;

		let response = await request;
		if( response.status === 200 ){
			this.props.favoriteRecommendation(recommendation);
			this.attemptedTokenRetry = false;
		} else if( response.status === 401 ){
			if( this.attemptedTokenRetry ){
				return;
			}
			if( process.env.NODE_ENV !== 'development' ){
				this.attemptedTokenRetry = true;
				await refreshToken();
				this.markAsFavorite(recommendation);	
			}
			return;
		} else {
			alert('There was an error marking the link visited. Please refresh and try again.');
			return;
		}
	}
	render() {
		let emptyText;
		//an empty concatenated string represents no active filters
		if( this.props.filters.search + this.props.filters.category + this.props.filters.skillLevel === '' ){
			if(this.props.filters.showAll){
				emptyText = 'You really haven’t read anything yet! What’s the deal?';
			} else {
				emptyText = 'View a link to keep track of your progress.';
			}
		} else {
			emptyText = 'You haven’t read any matching links.';
		}

		return (
			<section className="viewed">
				<header>
					<h1>Previously Absorbed Knowledge</h1>
				</header>
				<ul>
					{this.props.keyedLinks && this.props.recommendations.map(recommendation=>{
						const link = this.props.keyedLinks[recommendation['Link']];
						const url = ( link ) ? link['URL'] : '';
						const favoritedClasses = ( recommendation['Favorited'] ) ? 'favorite favorited' : 'favorite';
						return <li key={recommendation['Link']}>
							<a href={url} target="_blank" rel="noopener noreferrer" title={link['Notes']}>{recommendation['Link']}</a>
							<button className={favoritedClasses} onClick={(e)=>this.markAsFavorite(recommendation)}>
								<Heart />
							</button>
							<footer>
								<time dateTime="2018-12-25T16:30:24">Completed <Moment interval={0} fromNow date={recommendation['Date Viewed']}/></time> in <button onClick={this.filterByCategory} value={this.props.keyedLinks && link['Category']}>{this.props.keyedLinks && link['Category']}</button>
							</footer>
						</li>
					})}
					{/* TODO update this in both link lists to have different text for "empty because of search" vs "empty because out of links" */}
					{this.props.recommendations.length === 0 && <li className="empty">{emptyText}</li>}
				</ul>
			</section>
		);
	}
}

const mapStateToProps = (state)=>({
    keyedLinks:state.keyedLinks,
    recommendations:filteredLinks(state.links, state.keyedLinks, state.recommendations, state.filters, state.user).filter(recommendation=>recommendation['Date Viewed']).sort((a,b)=>{
		return a['Date Viewed'].valueOf() - b['Date Viewed'].valueOf();
	}).reverse(),
    filters:state.filters,
    user:state.user
});

//TODO make favoriting work
const mapActionsToProps = {
	favoriteRecommendation:favoriteRecommendation,
	updateFilters:updateFilters
};

export default connect(mapStateToProps, mapActionsToProps)(ViewedLinks);