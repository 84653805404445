import React, { Component } from 'react';
import {connect} from 'react-redux';

import './App.scss';

import Menu from './Components/Menu/Menu';
import NewLinks from './Components/NewLinks/NewLinks'
import ViewedLinks from './Components/ViewedLinks/ViewedLinks';
import Identify from './Components/Identify/Identify';

class App extends Component {
  render() {
    const hasIdentified = (this.props.user);

    return (
      <React.Fragment>
        {!hasIdentified && <Identify/>}
        {hasIdentified && 
          <React.Fragment>
            <Menu />
            <NewLinks />
            <ViewedLinks />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state)=>({
  user:state.user
});

export default connect(mapStateToProps)(App);
