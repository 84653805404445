import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { API_KEY, SPREADSHEET_ID, URL_PREFIX, EVERYONE_OPTION } from './sheets';

import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';

import {linksReducer, keyedLinksReducer, categoriesReducer, skillLevelsReducer, initialFiltersState, filtersReducer} from './reducers/linksReducer';
import recommendationsReducer from './reducers/recommendationsReducer';
import peopleReducer from './reducers/peopleReducer';
import userReducer from './reducers/userReducer';

import { UPDATE_PEOPLE } from './actions/peopleActions';
import { UPDATE_RECOMMENDATIONS } from './actions/recommendationsActions';
import { UPDATE_LINKS } from './actions/linksActions';



const allReducers = combineReducers({
	links: linksReducer,
	keyedLinks: keyedLinksReducer,
	categories: categoriesReducer,
	skillLevels: skillLevelsReducer,
	recommendations: recommendationsReducer,
	people: peopleReducer,
	user: userReducer,
	filters: filtersReducer
});

const initialData = {
	links:[],
	recommendations:[],
	people:[],
	categories:[],
	skillLevels:[],
	user:localStorage.getItem('PleaseRead:user'),
	filters: initialFiltersState
}

const store = createStore(
	allReducers, 
	initialData,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const loadData = async ()=>{
	if( process.env.NODE_ENV !== 'development' ){
		refreshToken();
	}

	const URL_SUFFIX = '/values/';
	const QUERY_PARAMETERS = '?prettyPrint=false&key='+API_KEY;

	let peopleRequest = await fetch(URL_PREFIX+SPREADSHEET_ID+URL_SUFFIX+'People!A:B'+QUERY_PARAMETERS);
	let peopleResponse = await peopleRequest.json();


	let linksRequest = await fetch(URL_PREFIX+SPREADSHEET_ID+URL_SUFFIX+'Links!A:F'+QUERY_PARAMETERS);
	let linksResponse = await linksRequest.json();

	let recommendationsRequest = await fetch(URL_PREFIX+SPREADSHEET_ID+URL_SUFFIX+'Recommendations!A:F'+QUERY_PARAMETERS);
	let recommendationsResponse = await recommendationsRequest.json();

	setupPeople(peopleResponse);
	setupLinks(linksResponse);
	setupRecommendations(recommendationsResponse);
}

const setupPeople = (peopleResponse)=>{
	const headers = peopleResponse.values.shift();
	const people = peopleResponse.values.map((row, index)=>zip(headers, row, index+2)).filter(person=>!person['Name'].includes(EVERYONE_OPTION)).sort();

	store.dispatch({
		type:UPDATE_PEOPLE,
		payload:{
			people:people
		}
	});
}

const setupLinks = (linksResponse)=>{
	//remove the first row of column headers
	const headers = linksResponse.values.shift();
	const categories = new Set();
	const skillLevels = new Set();

	const links = linksResponse.values.map((row, index)=>{
		const link = zip(headers, row, index+2);
		link.domain = getDomain(link['URL']);

		categories.add(link['Category']);
		skillLevels.add(link['Skill Level']);
		return link;
	});

	store.dispatch({
		type:UPDATE_LINKS,
		payload:{
			links:links,
			categories:[...categories],
			skillLevels:[...skillLevels]
		}
	});
}

const setupRecommendations = (recommendationsResponse)=>{
	const headers = recommendationsResponse.values.shift();
	const recommendations = recommendationsResponse.values.map((row, index)=>zip(headers, row, index+2));

	store.dispatch({
		type:UPDATE_RECOMMENDATIONS,
		payload:{
			recommendations:recommendations
		}
	});	
}


//zips the array of headers together with each row's values to create an object for each row
const zip = (headers, row, rowNumber = null)=>{
	return headers.reduce((accumulator, key, index)=>{
		return {
			...accumulator,
			[key]:row[index]
		};
	}, {row:rowNumber});
}

function getDomain(url) {
    var a = document.createElement('a');
    a.setAttribute('href', url);
    return a.hostname.replace('www.','');
}

export function changeColor(){
	const color = localStorage.getItem('PleaseRead:color');
	if( color ){
		document.documentElement.style.setProperty('--color-primary', color);
	}	
}

export async function refreshToken(){
	let response = await fetch(process.env.REACT_APP_TOKEN_API_URL_PREFIX+'refresh.php');
	if( response.status !== 200 ){
		alert('There’s an error with the site’s authentication!!! Let Riley know.');
	}
	return;
}

export async function getToken(){
	let request = await fetch(process.env.REACT_APP_TOKEN_API_URL_PREFIX+'token.json');
	let data = await request.json();
	return data.access_token;
}









loadData();
changeColor();

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
