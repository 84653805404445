import React, { Component } from 'react';
import {connect} from 'react-redux';
import {updateFilters, clearFilters} from '../../actions/linksActions';
import {updateUser} from '../../actions/userActions';
import {changeColor} from '../../index';

import './Menu.scss';

import yours from './../../images/yours.svg';
import everyone from '../../images/everyone.svg';
// import addLink from '../../images/add-link.svg';
import search from '../../images/search.svg';
import categories from '../../images/categories.svg';
import skillLevel from '../../images/skill-level.svg';
import color from '../../images/color.svg';

class Menu extends Component {
	constructor(){
		super();
		this.filterByCategory = this.filterByCategory.bind(this);
		this.filterBySkillLevel = this.filterBySkillLevel.bind(this);
		this.filterBySearch = this.filterBySearch.bind(this);
		this.handleYourLinksClick = this.handleYourLinksClick.bind(this);
		this.changeColor = this.changeColor.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
	}
	filterBySearch(e){
		if( e.target.value.length === 0 ){
			this.props.updateFilters('search','');
		}
		this.props.updateFilters('search', e.target.value);
	}
	filterByCategory(e){
		e.target.blur();
		this.props.updateFilters('category', e.target.value);
	}
	filterBySkillLevel(e){
		e.target.blur();
		this.props.updateFilters('skillLevel', e.target.value);
	}
	handleYourLinksClick(e){
		if( !this.props.filters.showAll ){
			this.props.updateUser('');
		} else {
			this.props.updateFilters('showAll', false);
		}
		e.target.blur();
	}
	clearFilters(e){
		e.target.blur();
		this.props.clearFilters();
	}
	changeColor(e){
		let color = e.target.value;
		localStorage.setItem('PleaseRead:color', color);
		changeColor();
		e.target.blur();
	}
	render() {
		return (
			<section className="menu">
				<menu>
					<ul className="menu-group links">
						<li className={(!this.props.filters.showAll) ? 'active' : undefined}>
							<button onClick={this.handleYourLinksClick}><img src={yours} alt="View your links"/></button>
							<div className="message">Hi {this.props.user.split(' ')[0]}!</div>
						</li>
						<li className={(this.props.filters.showAll) ? 'active' : undefined}>
							<button onClick={(e)=>{this.props.updateFilters('showAll', true); e.target.blur();}}><img src={everyone} alt="View everyone’s links"/></button>
							<div className="message">View all links</div>
						</li>
						{/* <li>
							<button><img src={addLink} alt="Suggest a link"/></button>
							<div className="form">
								<form>
									<input type="url" placeholder="https://website.com"/>
								</form>
							</div>
						</li> */}
					</ul>
					<ul className="menu-group filters">
						<li className={(this.props.filters.search) ? 'active': undefined}>
							<button onClick={this.clearFilters}><img src={search} alt="Search"/></button>
							<div className="form">
								<form onSubmit={e=>e.preventDefault()}>
									<input onChange={this.filterBySearch} type="search" placeholder="Search for a link"/>
								</form>
							</div>
						</li>
						<li className={(this.props.filters.category) ? 'active' : undefined}>
							<button onClick={this.clearFilters}><img src={categories} alt="Filter by category"/></button>
							<div className="filter-list">
								<ul>
									{this.props.categories.map(category=><li key={category} className={(this.props.filters.category && this.props.filters.category === category) ? 'active' : undefined}><button onClick={this.filterByCategory} value={category}>{category}</button></li>)}
								</ul>
							</div>
						</li>
						<li className={(this.props.filters.skillLevel) ? 'active' : undefined}>
							<button onClick={this.clearFilters}><img src={skillLevel} alt="Filter by skill level"/></button>
							<div className="filter-list">
								<ul>
									{this.props.skillLevels.map(skillLevel=><li key={skillLevel} className={(this.props.filters.skillLevel && this.props.filters.skillLevel === skillLevel) ? 'active' : undefined}><button onClick={this.filterBySkillLevel} value={skillLevel}>{skillLevel}</button></li>)}
								</ul>
							</div>
						</li>
					</ul>
					<ul className="menu-group settings">
						<li className="color">
							<button tabIndex="-1"><img src={color} alt="Change the interface color"/></button>
							<input type="color" onChange={this.changeColor}/>
						</li>
					</ul>
				</menu>
			</section>
		);
	}
}

const mapStateToProps = (state)=>({
    // products:state.products,
    categories:state.categories,
    skillLevels:state.skillLevels,
    filters:state.filters,
    user:state.user
});

const mapActionsToProps = {
	updateFilters:updateFilters,
	clearFilters:clearFilters,
	updateUser:updateUser
};

export default connect(mapStateToProps, mapActionsToProps)(Menu);