export const UPDATE_RECOMMENDATIONS = 'recommendations:updateRecommendations';
export const FAVORITE_RECOMMENDATION = 'recommendations:favoriteRecommendation';
export const VISIT_RECOMMENDATION = 'recommendations:visitRecommendation';

export function updateRecommendations(recommendations){
	return {
		type:UPDATE_RECOMMENDATIONS,
		payload:{
			recommendations:recommendations
		}
	}
}

export function favoriteRecommendation(recommendation){
	return {
		type:FAVORITE_RECOMMENDATION,
		payload:{
			recommendation:recommendation
		}
	}
}

export function visitRecommendation(recommendation, isNew){
	return {
		type:VISIT_RECOMMENDATION,
		payload:{
			recommendation:recommendation,
			isNew:isNew
		}
	}
}