export const UPDATE_LINKS = 'links:updateLinks';
export const UPDATE_FILTERS = 'links:updateFilters';
export const CLEAR_FILTERS = 'links:clearFilters';

export function updateLinks(links){
	return {
		type:UPDATE_LINKS,
		payload:{
			links:links
		}
	}
}

export function updateFilters(type, filter){
	return {
		type:UPDATE_FILTERS,
		payload:{
			type:type,
			filter:filter
		}
	}
}

export function clearFilters(){
	return {
		type:CLEAR_FILTERS
	}
}