import { UPDATE_PEOPLE } from '../actions/peopleActions';

export default function peopleReducer(state = '', action){
	switch(action.type){
		case UPDATE_PEOPLE:
			return action.payload.people;
		default:
			return state;
	}
}
